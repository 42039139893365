import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import LoadingSpinner from "../interfaceParts/LoadingSpinner"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import ChatInterface from '../interfaceParts/ChatInterface'

import Notification from '../interfaceParts/Notification'

import {
  assertLoggedIn,
  redirectTo,
  logout
} from '../redux/actionsAuth'

class InternalPage extends Component {
  componentDidMount() {
    this.props.dispatch(assertLoggedIn())
  }

  render() {

    if (this.props.shouldRedirectTo)
    {
      this.props.dispatch (redirectTo(null))
      return (<Navigate to={this.props.shouldRedirectTo} />)
    }

    var error = null
    if (this.props.error && (this.props.error.length > 0))
      error = <div className="error">{this.props.error}</div>  
    
    var contents = null
    contents = <ChatInterface />
    var that = this
    
    return (
      <div className={"App"}>
          {error}
          <LoadingSpinner show={this.props.loading} />
          <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
              <Navbar.Brand href="#home">S4IA</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <NavDropdown title={this.props.profile.name + " " + this.props.profile.surname} id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={(e)=>{that.props.dispatch (logout())}}>Logout</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container fluid>
            <Row className="flex-nowrap">
                <Col className="py-3">
                  <div className='' style={{overflowY:"auto"}}>
                    {contents}
                  </div>
                </Col>
            </Row>
          </Container>
          <Notification/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.auth.error,
    loggedIn: state.auth.loggedIn,
    loading: state.interfaceParams.loading,
    shouldRedirectTo: state.auth.shouldRedirectTo,
    profile: state.data.profile
  }
}


export default connect(mapStateToProps)(InternalPage)


