import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './redux/reducers'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import './css/main.scss'

import LoginPage from './pages/LoginPage'
import ResetPassPage from './pages/ResetPassPage';
import InternalPage from './pages/InternalPage';

const loggerMiddleware = createLogger()
function configureStore(preloadedState) {
  if (process.env["REACT_APP_DEBUG"] === "1")
    return createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(thunkMiddleware, loggerMiddleware)
    )
  else
    return createStore(
      rootReducer,
      preloadedState,
      applyMiddleware(thunkMiddleware)
    )
}

const store = configureStore ()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<InternalPage pageType='DashboardPage' />} />
                <Route exact path="/products" element={<InternalPage pageType='ProductsPage' />} />
                <Route exact path="/events" element={<InternalPage pageType='EventsPage' />} />
                <Route exact path="/contacts" element={<InternalPage pageType='ContactsPage' />} />
                <Route exact path="/admin" element={<InternalPage pageType='AdminPage' />} />
                <Route exact path="/tasks" element={<InternalPage pageType='TasksPage' />} />
                <Route exact path="/sales" element={<InternalPage pageType='SalesPage' />} />
                <Route exact path="/tags" element={<InternalPage pageType='TagsPage' />} />
                <Route exact path="/login" element={<LoginPage />} />
                <Route path="/reset" element={<ResetPassPage />} />
            </Routes>
        </BrowserRouter>
    </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
