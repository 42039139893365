import { Component } from "react";
import { connect } from 'react-redux'
import Table from 'react-bootstrap/Table';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

import { apiRequest, MODIFY_DATA_PARAM } from "../redux/actionsAPI";
import { MODIFY_INTERFACE_PARAM } from "../redux/actions";
import Button from "react-bootstrap/esm/Button";

class ChatInterface extends Component {
    state = {
        "searchQuery": "", //"une société belge réalisant des bénéfices paie des tantièmes importants à une société luxembourgeoise (avec pertes fiscales), qui est son administrateur mais est par ailleurs son actionnaire principal. Ces tantièmes sont-ils déductibles à l'impôt des sociétés en Belgique? Quels sont les risques de requalification?  ",//"A Belgian company makes a profit. It pays significant tantièmes to a Luxembourg company with tax losses, which is its director but is also its main shareholder. Are these fees deductible for corporate tax in Belgium?",//"une société belge réalisant des bénéfices paie des tantièmes importants à une société luxembourgeoise (avec pertes fiscales), qui est son administrateur mais est par ailleurs son actionnaire principal. Ces tantièmes sont-ils déductibles à l'impôt des sociétés en Belgique? Quels sont les risques de requalification?  ",
        "chatQuery": "",
        "unselectedRefs": {},
        "configText": "Respond in English. Base your answer on the following document extracts:",
        "separationOption": {value:0, label:"Simple"},
        "extractsNumber": 3

    }

    handleInputChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSelectChange = (e, inp) => {
        this.setState({ [inp.name]: e });
    };

    handleRefCBChange = e => {
        var cur = Object.assign ({}, this.state.unselectedRefs)
        cur[e.target.name] = !cur[e.target.name]
        this.setState ({unselectedRefs: cur})
    }

    componentDidMount() {
        this.props.dispatch (apiRequest({'endpoint': 'startSession'}))
    }

    componentDidUpdate (prevProps) {
        if (!prevProps.data.translatedQuery && this.props.data.translatedQuery) {
            this.setState ({chatQuery:this.props.data.translatedQuery})
            this.props.dispatch({type: MODIFY_DATA_PARAM, "paramName": "translatedQuery", "paramValue":null})
        }
    }

    onKeyUp(event) {
        if (event.keyCode === 13) {
            var that = this
            this.props.dispatch (apiRequest({'endpoint': 'chatRequest2', 
                                            'query':this.state.chatQuery, 
                                            'sessionID':this.props.data.sessionID, 
                                            'docRefs': this.props.data.docs.map (function (p) { if (!that.state.unselectedRefs[p.ref]) return p.ref; else return null }),
                                        }))
            //this.setState ({chatQuery:""})
        }
    }

    render () {
        var that = this

        var contextDocs = null
        if (this.props.data.docs && (this.props.data.docs.length > 0))
            contextDocs = this.props.data.docs.map (function (p, ic) {
                return <div key={"cDoc"+ic}>
                    <Form.Check // prettier-ignore
                        type="switch"
                        name={p.ref}
                        checked={!that.state.unselectedRefs[p.ref]}
                        onChange={that.handleRefCBChange}
                        inline
                    />
                    <a href={p.link} target="_blank">{p.title}</a>
                </div>
            })
            
        var chatItems = []
        if (that.props.data.conversation && (that.props.data.conversation.length > 0))
            chatItems = that.props.data.conversation.map (function (p, ic) {
                return <div key={"chatItem" + ic} className={"chatItem " + p.role}>
                    <div>
                        <span className="role">{(p.role === 'user') ? "You" : p.role}</span>
                        {p.htmlContent ? <div dangerouslySetInnerHTML={{__html: p.htmlContent}}/>:null}
                        {p.content}
                    </div>
                </div>
            })

        
        return <div className="ChatInterface">
            <div className="control mb-3">
                <label htmlFor="searchQuery" className="form-label">Search query to define the context</label>
                <textarea
                    className="form-control"
                    name="searchQuery"
                    required
                    onChange={this.handleInputChange}
                    value={this.state.searchQuery}
                    type="text"
                />
            </div>
            <Button type="primary" disabled={(that.state.searchQuery.length < 5) || that.props.data.openJobID } onClick={(e)=>{that.props.dispatch (apiRequest({'endpoint': 'searchRequest', 'query':that.state.searchQuery, 'sessionID':that.props.data.sessionID}, null, false))}}>
                Go
            </Button>

            {contextDocs ? <div className="mt-3">
                <h5>The documents used for the context:</h5>
                {contextDocs}
                <div className="control mb-3 mt-3 d-none" style={{marginTop:"40px !important;"}}>
                    <input
                        className="form-control"
                        name="chatQuery"
                        required
                        onChange={this.handleInputChange}
                        value={this.state.chatQuery}
                        type="text"
                        onKeyUp={this.onKeyUp.bind(this)}
                        disabled={that.props.data.openJobID !== null}
                    />
                </div>
                <div className="control mb-3">
                    <input
                        className="form-control"
                        name="chatQuery"
                        required
                        onChange={this.handleInputChange}
                        value={this.state.chatQuery}
                        type="text"
                        onKeyUp={this.onKeyUp.bind(this)}
                    />
                </div>
                <Button type="primary" className="mt-3" disabled={that.props.data.openJobID !== null} onClick={(e)=>{
                        this.props.dispatch (apiRequest({'endpoint': 'chatRequest2', 
                            'query':this.state.chatQuery, 
                            'sessionID':this.props.data.sessionID, 
                            'docRefs': this.props.data.docs.map (function (p) { if (!that.state.unselectedRefs[p.ref]) return p.ref; else return null }),
                        }))
                }}>
                    Generate answers
                </Button>
            </div>:null}

            {(chatItems.length > 0) ?
                <div className="mt-3 chat">
                    {chatItems}
                    {that.props.data.openJobID ? <div className={"chatItem sys "}>
                        <div>
                            Working...
                        </div>
                    </div>:null}
                </div>:null}
            


        </div>
    }
}

function mapStateToProps(state) {
    return {
        interface: state.interfaceParams,
        profile: state.data.profile,
        data: state.data
    }
}

export default connect(mapStateToProps)(ChatInterface)