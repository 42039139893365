// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/faded.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.loadingSpinnerContainer {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  text-align: center;\n  transition: 0.5s;\n  opacity: 0;\n  visibility: hidden;\n  z-index: 130000; }\n\ndiv.loadingSpinnerContainer div svg {\n  overflow: show; }\n\ndiv.loadingSpinnerContainer.show {\n  opacity: 1;\n  visibility: visible; }\n\ndiv.loadingSpinnerContainer div {\n  margin: 0;\n  position: absolute;\n  top: 50%;\n  transform: translate(0, -50%);\n  display: inline-block; }\n", "",{"version":3,"sources":["webpack://./src/css/LoadingSpinner.scss","webpack://./src/css/_config.scss"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,mDCRqC;EDSrC,kBAAkB;EAIlB,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,eAAe,EAAA;;AAGjB;EAEI,cAAc,EAAA;;AAGlB;EACE,UAAU;EACV,mBAAmB,EAAA;;AAGrB;EACE,SAAS;EACR,kBAAkB;EAClB,QAAQ;EACR,6BAA6B;EAC7B,qBAAqB,EAAA","sourcesContent":["@import \"_config.scss\";\n\ndiv.loadingSpinnerContainer {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background: $fadedBackground;\n  text-align: center;\n  -webkit-transition: 0.5s;\n  -moz-transition: 0.5s;\n  -o-transition: 0.5s;\n  transition: 0.5s;\n  opacity: 0;\n  visibility: hidden; \n  z-index: 130000;\n}\n\ndiv.loadingSpinnerContainer div svg\n{\n    overflow: show;\n}\n\ndiv.loadingSpinnerContainer.show {\n  opacity: 1;\n  visibility: visible; \n}\n\ndiv.loadingSpinnerContainer div {\n  margin: 0;\n   position: absolute;           \n   top: 50%;                     \n   transform: translate(0, -50%);\n   display: inline-block;\n}","$fadedBackground: url('/img/faded.png'); "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
