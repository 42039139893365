

import { GLOBAL_URL } from "./actionsAuth"
import axios from "axios";

import { 
    setNotification,
    setLoading,
    getAccessToken
 } from "./actions"

 import { authRefresh } from "./actionsAuth"
 import { MODIFY_INTERFACE_PARAM } from "./actions"

 export const MODIFY_DATA_PARAM = "MODIFY_DATA_PARAM"
 export const EXPAND_CONVERSATION = "EXPAND_CONVERSATION"

export function apiCommunication (conf, extraExecOnSuccess, withoutLoadingIndicator = false) {
    return dispatch => {
        if (!withoutLoadingIndicator)
            dispatch(setLoading(true))
        return fetch(GLOBAL_URL + "api", conf)
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({params:arguments}))
                return res.json()})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if (extraExecOnSuccess)
                        dispatch(extraExecOnSuccess)
                    if (!withoutLoadingIndicator)
                        dispatch(setLoading(false))
                },
                (error) => {
                    if (!withoutLoadingIndicator)
                        dispatch(setLoading(false))
                }
            )
    }
}

export function treatGenericResponse (response, dispatch) {
    if (response.error && (response.error.length > 0))
        dispatch(setNotification ("Error occured.", response.error))
    if (response.notification && (response.notification.length > 0))
        dispatch(setNotification ("Notification", response.notification))
    if (response.interfaceAction)
        dispatch({type:MODIFY_INTERFACE_PARAM, "paramName": response.interfaceAction.paramName, "paramValue": response.interfaceAction.paramValue})
    if (response.profile)
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "profile", "paramValue":response.profile})
    if (response.sessionID)
    {
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "sessionID", "paramValue":response.sessionID})
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "docs", "paramValue":[]})
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "dialog", "paramValue":[]})
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "usedQuery", "paramValue":undefined})

    }
    if (response.docs)
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "docs", "paramValue":response.docs})
    if (response.usedQuery)
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "usedQuery", "paramValue":response.usedQuery})
    if (response.translatedQuery)
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "translatedQuery", "paramValue":response.translatedQuery})
    if (response.dialog)
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "dialog", "paramValue":response.dialog})
    if (response.jobID)
    {
        dispatch({type: MODIFY_DATA_PARAM, "paramName": "openJobID", "paramValue":response.jobID})
        setTimeout (poolJob, 500, response.jobID, dispatch)
    }
    if (response.jobUpdates)
    {
        console.log (response.jobUpdates)
        if (response.jobUpdates.newAnswers.length > 0)
            dispatch({type: EXPAND_CONVERSATION, "newComs":response.jobUpdates.newAnswers})
        if (!response.jobUpdates.finished)
            setTimeout (poolJob, 500, response.jobUpdates.jobID, dispatch)
        else
            dispatch({type: MODIFY_DATA_PARAM, "paramName": "openJobID", "paramValue":null})
    }
    
}

export function poolJob (jobID, dispatch) {
    const conf = {
        method: "post",
        body: JSON.stringify({'endpoint': 'poolRequestState','jobID':jobID}),
        headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
      };
    return fetch(GLOBAL_URL + "api", conf)
        .then(res => { 
            return res.json()})
        .then(
            (result) => {
                treatGenericResponse(result, dispatch)
            },
            (error) => {
            }
        )
}

export function apiRequest (body, extraExecOnSuccess = null, withoutLoadingIndicator = false) {
    const conf = {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({ "Content-Type": "application/json", "Authorization": "Bearer " + getAccessToken() })
    };
    return apiCommunication (conf, extraExecOnSuccess, withoutLoadingIndicator)   
}


export function apiRequestWithFiles (body, file, extraExecOnSuccess = null) {
    let httpClient = axios.create ({baseURL:GLOBAL_URL})
    let formData = new FormData();

    formData.append("file", file);
    formData.append("otherParams", JSON.stringify(body))

    return dispatch => {
        dispatch(setLoading(true))
        return httpClient.post("filesApi", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + getAccessToken()
            },
            onUploadProgress: ((event) => {
                //dispatch(setLoading(true, Math.round((100 * event.loaded) / event.total)))
                })
            })
            .then(res => { 
                if (res.status === 403) 
                    dispatch(authRefresh({params:arguments}))
                return res.data})
            .then(
                (result) => {
                    treatGenericResponse(result, dispatch)
                    if (extraExecOnSuccess)
                        dispatch(extraExecOnSuccess)
                    dispatch(setLoading(false))
                },
                (error) => {
                    dispatch(setLoading(false))
                }
            )
    }
}
